import { ConfigState } from 'types/app/config';

export const configInitialState: ConfigState = {
  reservation_confirmations: [],
  reservation_tags: {
    additional_info: [],
    dietary_requirements: [],
  },
  reservation_durations: [],
  client_tags: [],
  languages: [],
  system_languages: [],
  widget_languages: [],
  available_widget_languages: [],
  week_days: [],
  first_day_of_week: [],
  account_roles: [],
  account_permissions: {},
  user_statuses: [],
  timezones: [],
  date_formats: [],
  user_restaurants: [],
  account_restaurants: [],
  account_waiters: [],
  countries: [],
  policies_and_terms: {},
  affiliate_restaurants: [],
  preset_permissions: {},
};
